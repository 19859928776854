@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background: hsl(0, 0%, 96%);
	font-family: 'Manrope', 'sans-serif';
}

.manrope {
	font-family: 'Manrope', sans-serif;
}

.inter {
	font-family: 'Inter', sans-serif;
}

.poppins {
	font-family: 'Poppins', sans-serif !important;
}

.nav li {
	position: relative;
	display: inline-block;
}

.nav li::after {
	content: '';
	position: absolute;
	left: 0;
	top: 103%;
	width: 0;
	height: 4px;
	border-radius: 12px;
	background-color: hsl(78, 55%, 51%);
	transition: width 0.3s ease-in-out;
}

.nav li:hover:after {
	width: 100%;
}

.position-inherit {
	position: inherit;
}

.eventSignUp::placeholder {
	font-size: 22px;
	color: hsla(0, 0%, 5%, 0.4);
}

input[type='text']:focus,
input[type='text']:hover,
input[type='text']:active {
	outline: 0;
}

.bg-radius-black {
	background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.056) 0%, rgba(0, 0, 0, 0.36) 100%);
}

.profileInputForm::placeholder {
	color: hsl(199, 26%, 16%);
}

input.profileInputForm:disabled {
	background-color: transparent;
}

.infoDropdown::after {
	content: '';
	position: absolute;
	top: -15%;
	right: 10%;
	transform: rotate(180deg);
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 16px solid #b2c73d;
	clear: both;
}

/* CONTENT STYLING */

.partners-content-wrapper ul {
	list-style-position: inside !important;
	margin-left: 16px;
}

.partners-content-wrapper li {
	position: relative;
	margin-top: 12px !important;
	margin-bottom: 12px;
	padding-left: 15px;
}

.partners-content-wrapper li::before {
	content: '';
	position: absolute;
	top: 8px;
	left: -16px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background-color: #b2c73d;
}

.carousel-content-wrapper p {
	display: -webkit-box;
	width: 100%;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.news-content-wrapper p {
	display: -webkit-box;
	width: 100%;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.content ul li {
	list-style-type: disc;
	margin-left: 40px;
}

/* CONTENT STYLING END */

/* NEWS GRID STYLES */
.wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  }

.box {
	cursor: pointer;
	transition: all 0.5s;
	width: 23.74%;
}

.info-box {
	float: left;
	cursor: pointer;
	transition: all 0.5s;
	width: 100%;
	height: 0;
	overflow: hidden;
	padding: 0;
	opacity: 0;
	color: #fff !important;
	
	&.open {
	  height: auto;
	  opacity: 1;
	}
  }

/* NEWS GRID STYLES */


/* CUSTOM CHECKBOX STYLES */

.form-control, .allInc-form {
	font-family: system-ui, sans-serif;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.1;
	display: flex;
	align-items: baseline;
}

.allInc-form span {
	font-size: 11px;
	margin-bottom: 0;
	margin-right: 4px;
}

.form-control + .form-control,
.allInc-form + .allInc-form {
	margin-top: 1em;
}

.form-control--disabled,
.allInc-form--disabled {
	color: var(--form-control-disabled);
	cursor: not-allowed;
}

input[type='checkbox']{
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	appearance: none;
	/* For iOS < 15 */
	background-color: var(--form-background);
	/* Not removed via appearance */
	margin: 0;

	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid currentColor;
	border-radius: 0.15em;
	transform: translateY(-0.075em);

	display: grid;
	place-content: center;
}

input[type='checkbox']::before {
	content: '';
	width: 0.65em;
	height: 0.65em;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	transform: scale(0);
	transform-origin: bottom left;
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em var(--form-control-color);
	/* Windows High Contrast Mode */
	background-color: #b2c73d;
}

.allInc-form input[type='checkbox']::before {
	background-color: #EE72AA;
}

input[type='checkbox']:checked::before {
	transform: scale(1);
}

input[type='checkbox']:focus {
	outline: max(2px, 0.15em) solid currentColor;
	outline-offset: max(2px, 0.15em);
}

.form-control input[type='checkbox']:disabled {
	--form-control-color: var(--form-control-disabled);
	color: var(--form-control-disabled);
	cursor: not-allowed;
}

/* CUSTOM CHECKBOX STYLES END */

/* SWIPER ADDITIONAL STYLES */
.paginationItem {
	font-family: 'Manrope', 'sans-serif';
	font-size: 16px;
	color: hsl(0, 0%, 14%);
	margin: 0 !important;
	opacity: 1 !important;
}

.swiper-pagination {
	height: fit-content !important;
	margin-top: 30px;
	margin-bottom: 40px;
	position: static;
	margin-left: auto;
	margin-right: auto;
	max-width: 70%;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
	background: none;
	width: fit-content;
}

.swiper-pagination-bullet {
	background-color: transparent;
	border-radius: 50px;
	padding: 5px 10px;
	font-weight: 500;
	height: 100%;
	font-size: 16px;
}

.swiper-pagination-bullet-active {
	background-color: #b2c73d;
	color: white;
}

.swiper-button-next {
	position: absolute !important;
	top: 30px;
	right: 50%;
}

.customNext,
.customPrev {
	position: absolute;
	top: 30px;
}

.swiper-pagination-bullet-active {
	background-color: #b2c73d;
	color: white;
}

.swiper-button-next {
	position: absolute !important;
	top: 30px;
	right: 50%;
}

.customNext,
.customPrev {
	position: absolute;
	top: 30px;
}

.swiper-button-disabled {
	display: none;
}

.mySwiper {
	display: flex;
	flex-direction: column-reverse;
}

.sideBarItem {
	position: relative;
	display: flex;
	align-items: baseline;
	text-align: left;
}

.sideBarItem::before {
	content: '';
	display: none;
	width: 8px;
	height: 8px;
	border-radius: 999px;
	background-color: #b2c73d;
	position: absolute;
	top: 8px;
	left: -16px;
}

.sideBarItem.active::before {
	display: block !important;
}

.sideBarItem.active {
	color: #0d0d0d;
}

/* SWIPER ADDITIONAL STYLES */

/* LOADER CSS */

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid hsl(78, 55%, 51%);
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: hsl(78, 55%, 51%) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loader {
	border: 10px solid #fff;
	border-top: 10px solid hsl(78, 55%, 51%);
	border-bottom: 10px solid hsl(78, 55%, 51%);
	border-radius: 50%;
	width: 5rem;
	height: 5rem;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* LOADER CSS END */

/* CUSTOM SCROLL CSS */

.speakerListWrapper::-webkit-scrollbar {
	width: 10px;
}

.speakerListWrapper::-webkit-scrollbar-track {
	background: none;
}

.speakerListWrapper::-webkit-scrollbar-thumb {
	background: #b2c73d;
	border-radius: 999px;
}

.speakerListWrapper::-webkit-scrollbar-thumb:hover {
	background: #85a833;
}

/* CUSTOM SCROLL CSS END */

/* CUSTOM INPUT FILE CSS */

.inputFile {
	opacity: 0;
	position: absolute;
	z-index: 0;
}

/* CUSTOM INPUT FILE CSS END */

.eventContentWrapper > *,
.text-wrapper > * {
	font-family: 'Manrope', sans-serif !important;
}

.text-wrapper > * {
	text-align: left !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.eventContentWrapper h1,
.text-wrapper h1 {
	font-size: 24px !important;
	line-height: 120% !important;
	font-weight: 700 !important;
	margin-bottom: 32px !important;
	text-align: left !important;
}

.eventContentWrapper h2,
.text-wrapper h2 {
	font-size: 20px !important;
	font-weight: 600 !important;
	text-align: left !important;
}

.eventContentWrapper h3,
.text-wrapper h3 {
	font-size: 18px !important;
	font-weight: 500 !important;
	text-align: left !important;
}

.eventContentWrapper p,
.text-wrapper p,
.eventContentWrapper span,
.text-wrapper span {
	font-size: 1.125rem !important;
	line-height: 1.75rem !important;
	margin-bottom: 20px !important;
	font-weight: 400 !important;
	text-align: left !important;
	background-color: unset !important;
}

.eventContentWrapper font,
.text-wrapper font {
	background-color: unset !important;
}

.eventContentWrapper iframe {
	max-width: 100% !important;
	width: 100%;
}

.text-wrapper {
	font-size: 16px !important;
	line-height: normal !important;
	margin-bottom: 20px !important;
	font-weight: 400 !important;
	text-align: left !important;
	color: hsla(0, 0%, 5%, 0.7) !important;
}

.text-wrapper iframe {
	width: 100% !important;
}

.eventContentWrapper ul,
.text-wrapper ul {
	list-style: disc;
	margin-left: 16px !important;
}

.eventContentWrapper li::marker .text-wrapper li::marker {
	color: #85a833 !important;
}

.eventContentWrapper li,
.text-wrapper li {
	font-size: 14px;
}

/* MOBILE STYLES */

@media (min-width: 1366px) {
	.box {
		width: 23.69% !important;
	}
}

@media (min-width: 768px) {
	.box {
		width: 48.6%;
	}
}

@media (max-width: 1024px) {
	.eventSignUp::placeholder {
		font-size: 14px;
	}
}

@media (max-width: 768px) {
	.customNext,
	.customPrev {
		display: none;
	}

	.swiper-pagination-bullet-active {
		font-size: 16px !important;
	}

	.swiper-pagination-bullet {
		font-size: 16px;
		margin-top: 5px !important;
		margin-bottom: 5px !important;
		height: 34px;
	}

	.swiper-pagination {
		margin-bottom: 10px;
		max-width: 100%;
	}

	.box {
		width: 100%;
	}
}

/* MOBILE STYLES END */
